import styles from "./InBuildPage.module.css";

export const InBuildPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.filter} />
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <h1 className={styles.left}>Uwaga! Strona w budowie!</h1>
          <h4 className={styles.middle}>
            Zapraszamy do kontaktu pod adresem mailowym:
          </h4>
          <h3 className={styles.strong}>kontakt@wnetrzarki.pl</h3>
        </div>
      </div>
    </div>
  );
};
